<template>
  <div>
  <el-descriptions class="margin-top" title="修改预约" :column="2" :size="size" border>
<!--    <template slot="extra">-->
<!--      <el-button type="primary" size="small">操作</el-button>-->
<!--    </template>-->
    <el-descriptions-item label="用户名">{{ recordOrder.uname }}</el-descriptions-item>
    <el-descriptions-item label="所属养老院">{{ managerE }}</el-descriptions-item>
    <el-descriptions-item label="管理员电话">{{ managerPhone }}</el-descriptions-item>
    <el-descriptions-item label="预约时间">{{ theDateTime }}</el-descriptions-item>
    <el-descriptions-item label="症状备注">{{ recordOrder.symptoms }}</el-descriptions-item>
    <el-descriptions-item label="预约状态">
      <el-tag v-if="recordOrder.status==1" size="small" type="warning">待接受</el-tag>
      <el-tag v-if="recordOrder.status==2" size="small" type="success">已确认</el-tag>
      <el-tag v-if="recordOrder.status==3" size="small" type="danger">已拒绝</el-tag>
    </el-descriptions-item>

  </el-descriptions>

  <el-button @click="appointAccept()" type="primary" style="margin-top: 10px">
    接受预约
  </el-button>
  <el-button @click="appointCancel()" type="danger" style="margin-top: 10px">
    拒绝/取消预约
  </el-button>
  </div>
</template>

<script>
export default {
  name: "orderChange",
  data () {
    return {
      size: '',
      recordOrder: [],
      theDateTime:'',
      managerE:'',
      managerPhone:'',
    };
  },
  methods: {
    getPhoneNumber(uid){
      this.$http.adornHeader({"Cross-Platform-Verification": "Cross-Platform-Access" })
      this.$http({
        url:this.$http.adornUrl('/enterprise/enterpriseuserinfo/app/getEnterprisePhoneByUid?uid='+uid),
        method: 'POST',
        // params: this.$http.adornParams({
        //   orderId: orderId
        // })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看回来啥")
          console.log(data)
          // this.recordOrder = data.recordOrder
          this.managerE = data.enterpriseInfo.ename
          this.managerPhone = data.enterpriseInfo.phone
        }else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    getTreatReport(){
      let orderId = this.$route.query.orderId
      this.$http.adornHeader({"Cross-Platform-Verification": "Cross-Platform-Access" })
      this.$http({
        url:this.$http.adornUrl('/doctor/recordorder/info/'+orderId),
        method: 'GET',
        params: this.$http.adornParams({
          orderId: orderId
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.recordOrder = data.recordOrder
          // this.uid = data.recordOrder.userId
          // console.log("看看预约人")
          // console.log(this.uid)
          this.getPhoneNumber(data.recordOrder.userId)
          let xTime= data.recordOrder.dateTime.replace(/-/g, '/')
          let yTime= new Date(xTime)
          yTime.setDate(yTime.getDate())
          let zTime= yTime
          let nowDay = zTime.getDate();
          let nowMonth = zTime.getMonth()+1;
          let nowYear = zTime.getFullYear();
          if(zTime.getHours()==12){
            this.theDateTime= nowYear+"-"+nowMonth+"-"+nowDay+"上午"
          }
          if(zTime.getHours()==18){
            this.theDateTime= nowYear+"-"+nowMonth+"-"+nowDay+"下午"
          }
        }else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    appointAccept(){
      this.$http.adornHeader({"Cross-Platform-Verification": "Cross-Platform-Access" })
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/updateStatus'),
        method: 'GET',
        params: {
          orderId: this.recordOrder.orderId,
          statusType: 2,
        }
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    appointCancel() {
      this.$http.adornHeader({"Cross-Platform-Verification": "Cross-Platform-Access" })
      this.$http({
        url: this.$http.adornUrl('/doctor/recordorder/cancelOrder'),
        method: 'get',
        params: this.$http.adornParams({orderId: this.recordOrder.orderId})
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log("看看反馈的数据")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '取消成功',
            type: 'success'
          })
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
  },
  created() {
    this.getTreatReport()
    // this.getPhoneNumber()
  },
}
</script>

<style scoped>

</style>